import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { currentDeild } from '../../actions/currentDeild';
import { getLid } from '../../actions/getLid';
import { getAllGames } from '../../actions/getAllGames';
import { getUmferdir } from '../../actions/getUmferdir';
import { getUmferd } from '../../actions/getUmferd';
import { getPickedTeam } from '../../actions/getPickedTeam';
import { getSelectedFlokkur } from '../../actions/getSelectedFlokkur';
import FantasyBanner from '../FantasyBanner/FantasyBanner';
import './filter.css';


const Filter = (props) => {

    const states = useSelector(state => state);
    const dispatch = useDispatch();
    const [deildir, setDeildir] = useState(['Besta deild Karla', 'Besta deild Kvenna - efri', 'Besta deild Kvenna - neðri', 'Besta deild Kvenna', 'Besta deild Karla - efri', 'Besta deild Karla - neðri']);
    const [flokkar, setFlokkar] = useState(['Markahæstu', 'Gul spjöld', 'Rauð spjöld']);

    useEffect(() => {
        //window.scrollTo(0, 0);
        fetch(`/API/felog/${states.deild}`,{
            mode: 'same-origin',
            headers: {
                'Content-Type': 'application/json'
            },
        })
        .then(res => res.json())
        .then(
            (result) => {
                dispatch(getLid(result.MotStadaSvar.ArrayMotStada.MotStada));
            },
            (error) => {
                console.log(error);
            }
        )
    }, [states.deild]);

    useEffect(() => {
        //console.log(states);
        //window.scrollTo(0, 0);
        fetch(`/API/leikir/${states.deild}`, {
            mode: 'same-origin',
            headers: {
                'Content-Type': 'application/json'
            },
        })
        .then(res => res.json())
        .then(
            (result) => {
                //var temp = [];
                dispatch(getAllGames(result.MotLeikirSvar));
                
                var temp = [];
                for(let i = 0; i < result.MotLeikirSvar.ArrayMotLeikir.MotLeikur.length; i++) {
                    if(!temp.includes(result.MotLeikirSvar.ArrayMotLeikir.MotLeikur[i].UmferdNumer)) {
                        temp.push(result.MotLeikirSvar.ArrayMotLeikir.MotLeikur[i].UmferdNumer);
                    }
                }
                dispatch(getUmferdir(temp.sort(function(a, b){return a-b})));
            },
            (error) => {
                console.log(error);
            }
        )
    }, [states.deild]);

    /*useEffect(() => {
        //console.log(states);
        window.scrollTo(0, 0);
        fetch(`/API/leikir/${states.deild}`, {
            mode: 'same-origin',
            headers: {
                'Content-Type': 'application/json'
            },
        })
        .then(res => res.json())
        .then(
            (result) => {
                var temp = [];
                for(let i = 0; i < result.MotLeikirSvar.ArrayMotLeikir.MotLeikur.length; i++) {
                    if(!temp.includes(result.MotLeikirSvar.ArrayMotLeikir.MotLeikur[i].UmferdNumer)) {
                        temp.push(result.MotLeikirSvar.ArrayMotLeikir.MotLeikur[i].UmferdNumer);
                    }
                }
                dispatch(getUmferdir(temp.sort(function(a, b){return a-b})));
            },
            (error) => {
                console.log(error);
            }
        )
    }, [states.deild]);*/

    const handler = (event) => {
        const value = event.target.value;
        console.log(value);
        if(value == "Besta_deild_Karla") {
            dispatch(currentDeild("bestadeild_karla.php"));
        } else if(value == "Besta_deild_Kvenna") {
            dispatch(currentDeild("bestadeild_kvenna.php"));
        } else if(value == "Besta_deild_Karla_-_efri") {
            dispatch(currentDeild("bestadeild_karla_efri.php"));
        } else if(value == "Besta_deild_Karla_-_neðri") {
            dispatch(currentDeild("bestadeild_karla_nedri.php"));
        } else if(value == "Besta_deild_Kvenna_-_efri") {
            dispatch(currentDeild("bestadeild_kvenna_efri.php"));
        } else if(value == "Besta_deild_Kvenna_-_neðri") {
            dispatch(currentDeild("bestadeild_kvenna_nedri.php"));
        }
    }

    const handlerUmferd = (event) => {
        const value = event.target.value;
        dispatch(getUmferd(value));
    }

    const handlerLid = (event) => {
        const value = event.target.value;
        dispatch(getPickedTeam(value));
    }

    const handlerFlokkar = (event) => {
        const value = event.target.value;
        dispatch(getSelectedFlokkur(value));
    }

    if(props.filters == "allt") {
        return (
            <div>
                <div class="filter-container-mobile">
                    <div class="deild-mobile">
                        <p>Flokka eftir deild</p>
                        <select onChange={handler} name="deildir" id="deildir">
                            {deildir.map(deild => (
                                <option value={deild.replaceAll(" ", "_")}>{deild}</option>
                            ))}
                        </select>
                    </div>
                </div>
                <div class="filter-container">
                    <div class="deild">
                        <p>Flokka eftir deild</p>
                        <select onChange={handler} name="deildir" id="deildir">
                            {deildir.map(deild => (
                                <option value={deild.replaceAll(" ", "_")}>{deild}</option>
                            ))}
                        </select>
                    </div>
                    <div class="lið">
                        <p>Flokka eftir liði</p>
                        <select onChange={handlerLid} name="lið" id="lið">
                            <option selected>Öll lið</option>
                            {states.lid.map(lid => (
                                <option value={lid.FelagNafn}>{lid.FelagNafn}</option>
                            ))}
                        </select>
                    </div>
                    <div class="umferð">
                        <p>Flokka eftir umferð</p>
                        <select onChange={handlerUmferd} name="umferð" id="umferð">
                            <option>Allar umferðir</option>
                            {states.umferdir.map(umferd => (
                                <option value={umferd}>{umferd}</option>
                            ))}
                        </select>
                    </div>
                    <div class="rest">
                        <p onClick={() => {
                            let umferdirElement = document.getElementById("umferð");
                            umferdirElement.value = "Allar umferðir";
                            let lidElement = document.getElementById("lið");
                            lidElement.value = "Öll lið";
                            dispatch(getUmferd("Allar umferðir"));
                            dispatch(getPickedTeam("Öll lið"));
                        }}>ENDURRÆSA SÍU</p>
                    </div>
                </div>
            </div>
            )
    } else if(props.flokkar == "flokkar") {
        return (
            <div>
                <div class="filter-container-mobile">
                    <div class="deild-mobile">
                        <p>Flokka eftir deild</p>
                        <select onChange={handler} name="deildir" id="deildir">
                            {deildir.map(deild => (
                                <option value={deild.replaceAll(" ", "_")}>{deild}</option>
                            ))}
                        </select>
                    </div>
                    <div class="deild-mobile">
                        <p>Flokka eftir</p>
                        <select style={{width: '80%'}} onChange={handlerFlokkar} name="flokkar" id="flokkar">
                            {flokkar.map(deild => (
                                <option value={deild.replaceAll(" ", "_")}>{deild}</option>
                            ))}
                        </select>
                    </div>
                </div>
                <div class="filter-container">
                    <div class="deild">
                        <p>Flokka eftir deild</p>
                        <select onChange={handler} name="deildir" id="deildir">
                            {deildir.map(deild => (
                                <option value={deild.replaceAll(" ", "_")}>{deild}</option>
                            ))}
                        </select>
                    </div>
                    <div class="deild">
                        <p>Flokka eftir</p>
                        <select onChange={handlerFlokkar} name="flokkar" id="flokkar">
                            {flokkar.map(deild => (
                                <option value={deild.replaceAll(" ", "_")}>{deild}</option>
                            ))}
                        </select>
                    </div>
                    <div class="rest">
                    </div>
                </div>
            </div>
            )
    } else {
        return (
            <div>
                <div class="filter-container-mobile">
                    <div class="deild-mobile">
                        <p>Flokka eftir deild</p>
                        <select onChange={handler} name="deildir" id="deildir">
                            {deildir.map(deild => (
                                <option value={deild.replaceAll(" ", "_")}>{deild}</option>
                            ))}
                        </select>
                    </div>
                </div>
                <div class="filter-container">
                    <div class="deild">
                        <p>Flokka eftir deild</p>
                        <select onChange={handler} name="deildir" id="deildir">
                            {deildir.map(deild => (
                                <option value={deild.replaceAll(" ", "_")}>{deild}</option>
                            ))}
                        </select>
                    </div>
                    <div class="rest">
                    </div>
                </div>
            </div>
        )
    }
}


export default Filter;

