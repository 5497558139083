import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './Footer.css';


const FooterItems = () => {


    return (
        <div class="footer-items-container">
            {/*<div class="row">
                <div class="info-logo">
                    <img src='logo/besta_deildin_hvitt_logo.svg' />
                </div>
                <div class="info">
                    <div class="info-left">
                        <p>Bestadeildin ehf.</p>
                        <p>210 Garðabær</p>
                        <a><p>besta@bestadeildin.is</p></a>
                        <a><p>535 7000</p></a>
                    </div>
                    <div class="info-middle">
                        <a><p>Leikir</p></a>
                        <a><p>Úrslit</p></a>
                        <a><p>Stöðutöflur</p></a>
                        <a><p>Lið</p></a>
                    </div>
                    <div class="info-right">
                        <a><p>Tölfræði</p></a>
                        <a><p>Fjölmiðlar</p></a>
                        <a><p>Hafa samband</p></a>
                        <p>Lorem ipsum</p>
    </div>
                </div>
            </div>*/}
            <div class="bottom-footer">
                {/*<p>KT: 630402-3870 | VSK númer: 75030</p>*/}
                <p>Íslenskur toppfótbolti - besta@bestadeildin.is</p>
                <p>{'Bestadeildin' + ' ' + new Date().getFullYear() + '. Allur réttur áskilinn.'}</p>
            </div>
        </div>
        )
    }


export default FooterItems;

