import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import './Lid.css';


const Lid = () => {

    const states = useSelector(state => state);

    useEffect(() => {
        
    }, []);

    const accentsTidy = function(s){
        var r=s.toLowerCase();
        r = r.replace(new RegExp(/\s/g),"");
        r = r.replace(new RegExp(/[àáâãäå]/g),"a");
        r = r.replace(new RegExp(/æ/g),"ae");
        r = r.replace(new RegExp(/þ/g),"th");
        r = r.replace(new RegExp(/ð/g),"d");
        r = r.replace(new RegExp(/ç/g),"c");
        r = r.replace(new RegExp(/[èéêë]/g),"e");
        r = r.replace(new RegExp(/[ìíîï]/g),"i");
        r = r.replace(new RegExp(/ñ/g),"n");                
        r = r.replace(new RegExp(/[òóôõö]/g),"o");
        r = r.replace(new RegExp(/[ùúûü]/g),"u");
        r = r.replace(new RegExp(/[ýÿ]/g),"y");
        r = r.replace(new RegExp(/\W/g),"");
        return r;
    };

    if(states.games && Object.keys(states.games).length === 0 && Object.getPrototypeOf(states.games) === Object.prototype) {
        return (
            <div></div>
        )
    } else {
        return (
            <div class="lid-lid-cont">
                {states.lid.map(i => (
                    <div class="lid-lid" onClick={() => {
                        if(i.FelagNafn == "Leiknir R.") {
                            const newWindow = window.open('https://www.leiknir.com', '_blank', 'noopener,noreferrer')
                            if (newWindow) newWindow.opener = null
                        } else if(i.FelagNafn == "Víkingur R.") {
                            const newWindow = window.open('https://www.vikingur.is', '_blank', 'noopener,noreferrer')
                            if (newWindow) newWindow.opener = null
                        } else if(i.FelagNafn == "Þróttur R.") {
                            const newWindow = window.open('https://www.trottur.is', '_blank', 'noopener,noreferrer')
                            if (newWindow) newWindow.opener = null
                        } else if(i.FelagNafn == "Selfoss") {
                            const newWindow = window.open('https://www.selfoss.net/knattspyrna', '_blank', 'noopener,noreferrer')
                            if (newWindow) newWindow.opener = null
                        } else {
                            const newWindow = window.open('https://www.'+accentsTidy(i.FelagNafn)+'.is', '_blank', 'noopener,noreferrer')
                            if (newWindow) newWindow.opener = null
                        }
                    }}>
                        <img src={"/teamlogos/" + i.FelagNumer + ".png"}/>
                        <p>{i.FelagNafn}</p>
                    </div>
                ))}
            </div>
        )
    }
}


export default Lid;

