import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { gengiLida } from '../../actions/gengiLida';

import './Stada.css';


const Stada = () => {

    const [sidustuFimm, setSidustuFimm] = useState({});
    const states = useSelector(state => state);
    const dispatch = useDispatch();

    useEffect(() => {
        //window.scrollTo(0, 0);
        fetch(`/API/leikir/${states.deild}`, {
            mode: 'same-origin',
            headers: {
                'Content-Type': 'application/json'
            },
        })
        .then(res => res.json())
        .then(
            (result) => {
                const res = result.MotLeikirSvar.ArrayMotLeikir.MotLeikur;
                var temp = {};
                for(let i = 0; i < res.length; i++) {
                    if(!temp[res[i].FelagHeimaNafn]) {
                        if(res[i].UrslitHeima != "") {
                            if(Number(res[i].UrslitHeima) > Number(res[i].UrslitUti)) {
                                temp[res[i].FelagHeimaNafn] = ['w'];
                                temp[res[i].FelagUtiNafn] = ['l'];
                            } else if(Number(res[i].UrslitHeima) < Number(res[i].UrslitUti)) {
                                temp[res[i].FelagUtiNafn] = ['w'];
                                temp[res[i].FelagHeimaNafn] = ['l'];
                            } else {
                                temp[res[i].FelagUtiNafn] = ['d'];
                                temp[res[i].FelagHeimaNafn] = ['d'];
                            }
                        } else {
                            temp[res[i].FelagUtiNafn] = ['n'];
                            temp[res[i].FelagHeimaNafn] = ['n'];
                        }
                    } else {
                        if(res[i].UrslitHeima != "") {
                            if(Number(res[i].UrslitHeima) > Number(res[i].UrslitUti)) {
                                temp[res[i].FelagHeimaNafn] = [...temp[res[i].FelagHeimaNafn], 'w'];
                                temp[res[i].FelagUtiNafn] = [...temp[res[i].FelagUtiNafn], 'l'];
                            } else if(Number(res[i].UrslitHeima) < Number(res[i].UrslitUti)) {
                                temp[res[i].FelagUtiNafn] = [...temp[res[i].FelagUtiNafn], 'w'];
                                temp[res[i].FelagHeimaNafn] = [...temp[res[i].FelagHeimaNafn], 'l'];
                            } else {
                                temp[res[i].FelagUtiNafn] = [...temp[res[i].FelagUtiNafn], 'd'];
                                temp[res[i].FelagHeimaNafn] = [...temp[res[i].FelagHeimaNafn], 'd'];
                            }
                        } else {
                            temp[res[i].FelagUtiNafn] = [...temp[res[i].FelagUtiNafn], 'n'];
                            temp[res[i].FelagHeimaNafn] = [...temp[res[i].FelagHeimaNafn], 'n'];
                        }
                    }
                }

                //dispatch(gengiLida(temp));
                //console.log(temp);
                setSidustuFimm(temp);
            },
            (error) => {
                console.log(error);
            }
        )
    }, [states.deild]);

    const reverseAndLast5 = (arr) => {
        /*if(arr == undefined) {
            return [];
        } else {
            arr = arr.slice(0,5);
            return arr;
        }*/


        let temp = [];
        if(arr == undefined) {
            return [];
        } else if(arr.slice(0,5).includes('n')) {
            arr = arr.slice(0,5).reverse();
            return arr;
        } else {
            for(let i = 0; i < arr.length; i++) {
                if(arr[i] != 'n') {
                    temp.push(arr[i]);
                } else {
                    return temp.slice(-5).reverse();
                }
            }
            return temp.slice(-5).reverse();
        }

    }

    if(states.games && Object.keys(states.games).length === 0 && Object.getPrototypeOf(states.games) === Object.prototype && sidustuFimm == {}) {
        return (
            <div></div>
        )
    } else {
        return (
            <div class="stada-cont">
                <div class="col-names">
                    <p class="pos hide-mobile">Staða</p>
                    <p class="lid-imgText">Lið</p>
                    <p class="played">L</p>
                    <p class="played hide-mobile">U</p>
                    <p class="played hide-mobile">J</p>
                    <p class="played hide-mobile">T</p>
                    <p class="skorad-fengid hide-mobile">M</p>
                    <p class="played">+/-</p>
                    <p class="played">S</p>
                    <p class="sidustu5">Síðustu 5</p>
                </div>
                <div class="stada-container">
                    {states.lid.map((i, j) => (
                        <div class="lid-cont">
                            <p class="pos">{states.deild == 'bestadeild_karla_nedri.php' ? j+7 : j+1}</p>
                            <div class="lid-imgText">
                                <img src={"/teamlogos/" + i.FelagNumer + ".png"}/>
                                <p>{i.FelagNafn}</p>
                            </div>
                            <p class="played">{i.LeikirAlls}</p>
                            <p class="played hide-mobile">{i.LeikirUnnir}</p>
                            <p class="played hide-mobile">{i.LeikirJafnt}</p>
                            <p class="played hide-mobile">{i.LeikirTap}</p>
                            <p class="skorad-fengid hide-mobile">{i.MorkSkorud + ":" + i.MorkFenginASig}</p>
                            <p class="played">{i.MorkMisMunur}</p>
                            <p class="played">{i.Stig}</p>
                            {/*<p class="sidustu5">{reverseAndLast5(sidustuFimm[i.FelagNafn])}</p>*/}
                            <div class="sidustu5-container">
                                {reverseAndLast5(sidustuFimm[i.FelagNafn]).map(n => {
                                    return(
                                        <div class={n}></div>
                                    )
                                })}
                            </div>
                        </div>
                    ))}
                </div>
            </div>

        )
    }
}


export default Stada;
